import { Dialog,Image } from "antd-mobile"
//const BASE_URL= 'http://local.crm.com'
const BASE_URL= 'https://manage.yishush.cn'
const WEB_URL = 'https://client.yishush.cn'
const MINIPROGRAM_URL = 'https://miniprogram.yishush.cn'
const showConfirm = async (content) => {
  return await Dialog.confirm({
    title:'提示',
    content
  })
}
const userinfo = () => {
  return JSON.parse(localStorage.getItem('crm_client_userinfo')) 
}

const getYsOutsideUser = () => {
  return JSON.parse(localStorage.getItem('ys_outside_user'))
}
const reload = (ms=2000) => {
  setTimeout(()=>{
    window.location.reload()
  },ms)
}
const delay = (ms=2000) => {
  return new Promise((resolve) => {
    setTimeout(resolve,ms)
  })
}
const renderImg = (img,width=100,serial_number='') => {
  const arr = img.split(',')
  return arr.map((item,index) => {

    return serial_number ? (
      <Image src={MINIPROGRAM_URL + item} key={index} width={width}/>
    ) : (
      <Image src={BASE_URL + item} key={index} width={width}/>
    )
  })
}

const renderChattergeeImg = (img,width=100) => {
  const arr = img.split(',')
  return arr.map((item,index) => {
    return item.includes('jiandang') ? (
      <Image src={MINIPROGRAM_URL + item} key={index} width={width} fit="cover"/>
    ) : (
      <Image src={BASE_URL + item} key={index} width={width} fit="cover"/>
    )
  })
}
const strReplace = (value,start,filler='*') => {
  if(value.length < start) return value
  let str = filler.repeat(value.length-start)
  return value.substr(0,start) + str
}

const renderPhone = (phone) => {
  if(phone && phone.length === 11){
    return phone.substr(0,3)+'****'+phone.substr(7,11)
  }
  return phone
}

const renderCaptcha = () => {
  return `/common/captcha?random=${Math.random()}`
}

const intervalTime = (startTime,endTime) => {
  if(startTime >= endTime){
    return {
      minute:0,
      second:0
    }
  }
  
  let time = endTime - startTime
  let m = Math.floor(time/(1000*60)%60)
  let s = Math.floor(time/1000%60)
  return {
    minute:m,
    second:s
  }
}
//等额本息
const equalityInterset = (money,nper,rant) => {
  let data = {}
  let month_rant = rant/100/12
  let pow = Math.pow(1+month_rant,nper)
  let month_payment = (money * month_rant * pow) / (pow -1)
  let total_interest = ((money * month_rant * pow) / (pow -1)) * nper - money

  data = {
    nper,
    rant,
    loan_amount:money,
    month_payment : month_payment.toFixed(2),
    total_interest : total_interest.toFixed(2), //总利息
    money_interest : (money + parseFloat(total_interest)).toFixed(2)  //本息合计
  }

  let list = []
  for (let i = 0; i < nper; i++) {
    list[i] = {
      key:i,
      month_payment : data.month_payment,
    }
    if(i === 0){
      let month_interest =  (money * month_rant).toFixed(2) //利息
      let month_corpus = (parseFloat(data.month_payment) - month_interest).toFixed(2)  //本金
      list[i]['month_interest'] = month_interest 
      list[i]['month_corpus'] =  month_corpus
      list[i]['residue_money'] = (parseFloat(money) - parseFloat(month_corpus)).toFixed(2)
    }else{
      let month_interest = (parseFloat(list[i-1]['residue_money']) * month_rant).toFixed(2)
      let month_corpus = (parseFloat(data.month_payment) - month_interest).toFixed(2)  //本金
      list[i]['month_interest'] = month_interest 
      list[i]['month_corpus'] =  month_corpus
      list[i]['residue_money']  = (parseFloat(list[i-1]['residue_money']) - parseFloat(month_corpus)).toFixed(2)
      //list[i]['residue_money'] = (money - calculate(list)).toFixed(2)
    }

    if(i === (nper-1)  ){
      list[i]['month_corpus'] = list[i-1]['residue_money']
      list[i]['month_interest'] = (list[i]['month_payment']*1000 - list[i]['month_corpus']*1000)/1000
      list[i]['residue_money'] = '0.00'
    }
  }
  //list[nper-1]['residue_money'] = '0.00'
  data.list = list
  return data
}

//等额本金
const equalityCorpus = (money,nper,rant) => {
  let data = {
    nper,
    rant,
    loan_amount:money
  }
  let month_rant = rant/100/12 //月利率
  //let month_corpus = (money / nper).toFixed(2) //月还本金
  let month_corpus = Math.floor(money/nper*100)/100
  let list = []
  for (let i = 0; i <nper; i++) {
    list[i] = {
      key:i,
      month_corpus
    }
    let month_interest = 0
    let residue_money = 0
    if(i === 0){
      month_interest = (money * month_rant).toFixed(2) //月还利息
      residue_money =  money - month_corpus //剩余本金 = 总金额 - 已还本金
    }else{
      month_interest = (parseFloat(list[i-1].residue_money) * month_rant).toFixed(2)
      residue_money = (parseFloat(list[i-1]['residue_money']) - parseFloat(month_corpus)).toFixed(2)
    }

    let month_payment = (month_corpus*10000 + month_interest*10000) / 10000 //月还金额 = 本金+利息
    list[i]['month_interest'] = month_interest
    list[i]['month_payment'] = month_payment
    list[i]['residue_money'] = residue_money 
    if(i === (nper-1)  ){
      list[i]['month_payment'] = list[i-1]['residue_money']
      list[i]['month_interest'] = (list[i]['month_payment']*10000 - month_corpus*10000)/10000
      list[i]['residue_money'] = '0.00'
    }
  }
  data.list = list
  data.month_payment = list[0]['month_payment']

  let total_interest = list.reduce((prev,cur) => {
    return prev + parseFloat(cur.month_interest) 
  },0)
  data.total_interest = total_interest.toFixed(2) //总利息
  data.money_interest = (money + parseFloat(data.total_interest)).toFixed(2) //本息合计
  return data
}


//分期利率
const installmentRate = (money,nper,rate) => {
  // month_corpus 月还本 
  // month_interest 月还息  
  // month_payment 月还款
  let data = {
    nper,
    rant:rate,
    loan_amount:money
  }
  let month_corpus =  Math.floor(money/nper*100)/100
  let month_interest = money * rate / 100
  let month_payment = (month_corpus * 1000 + month_interest*1000) / 1000
  let list = []
  for (let i = 0; i <nper; i++) {
    list[i] = {
      key:i,
      month_corpus,
      month_interest,
      month_payment,
      residue_money:'-'
    }
  }

  data.list = list
  data.month_payment = month_payment
    
  data.total_interest = month_interest * nper
  data.money_interest = (money + parseFloat(data.total_interest)).toFixed(2) //本息合计
  
  let irr = calculateIRR(nper, 0-month_payment,money,0,0,0.1)
  
  data.irr = (irr*12*100).toFixed(2)
  return data
}

// let nper = 12; // 总共分期数  
// let pmt = -10240; // 每期还款金额  
// let pv = 120000; // 贷款总额  
// let fv = 0; // 未来值，通常设为0  
// let type = 0; // 还款时间类型，0表示期末，1表示期初  
function calculateIRR(periods, payment, presentVal, futureVal, type, estimate) {
  let FINANCIAL_PRECISION = 0.0000001;
  let FINANCIAL_MAX_ITERATIONS = 20;
  let y, y0, y1, x0, x1 = 0, f = 0, i = 0;
  let rate = estimate;
  if (Math.abs(rate) < FINANCIAL_PRECISION) {
      y = presentVal * (1 + periods * rate) + payment * (1 + rate * type) * periods + futureVal;
  } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = presentVal * f + payment * (1 / rate + type) * (f - 1) + futureVal;
  }
  y0 = presentVal + payment * periods + futureVal;
  y1 = presentVal * f + payment * (1 / rate + type) * (f - 1) + futureVal;

  // 牛顿割线法求根
  i = x0 = 0.0;
  x1 = rate;
  while ((Math.abs(y0 - y1) > FINANCIAL_PRECISION) && (i < FINANCIAL_MAX_ITERATIONS)) {
      rate = (y1 * x0 - y0 * x1) / (y1 - y0);
      x0 = x1;
      x1 = rate;

      if (Math.abs(rate) < FINANCIAL_PRECISION) {
          y = presentVal * (1 + periods * rate) + payment * (1 + rate * type) * periods + futureVal;
      } else {
          f = Math.exp(periods * Math.log(1 + rate));
          y = presentVal * f + payment * (1 / rate + type) * (f - 1) + futureVal;
      }

      y0 = y1;
      y1 = y;
      ++i;
  }
  return rate;
} 
// const calculate = (data) => {
//   let payment_corpus =  data.reduce((prev,cur)=> {
//     return prev + parseFloat(cur.month_corpus) 
//   },0)
//   return payment_corpus
// }

export {
  BASE_URL,
  WEB_URL,
  MINIPROGRAM_URL,
  showConfirm,
  userinfo,
  getYsOutsideUser,
  reload,
  delay,
  renderImg,
  renderChattergeeImg,
  strReplace,
  renderPhone,
  renderCaptcha,
  intervalTime,
  equalityInterset,
  equalityCorpus,
  installmentRate
}