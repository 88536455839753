import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavBar,Skeleton } from 'antd-mobile'
export default function Chattergee() {
  const [title,setTitle] = useState(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
    document.title = '乾太健'
    if(pathname.includes('home')){
      setTitle('首页')
    }else if(pathname.includes('tool')){
      setTitle('乾太健 钱包')
    }else if(pathname.includes('user')){
      setTitle('客户信息 ')
    }else if(pathname.includes('auth')){
      setTitle('实名认证 ')
    }else if(pathname.includes('purse')){
      setTitle('开通钱包 ')
    }else if(pathname.includes('send')){
      setTitle('发放积分 ')
    }else if(pathname.includes('list')){
      setTitle('我的客户')
    }else if(pathname.includes('add')){
      setTitle('新增建档')
    }else if(pathname.includes('test')){
      setTitle('测试页面')
    }
  },[pathname])
  return (
    <Suspense fallback={
        <>
          <Skeleton.Title animated />
          <Skeleton.Paragraph lineCount={5} animated />
        </>
      }
    >
      <NavBar 
        className='crm-header' 
        backArrow={!pathname.includes('tool') && !pathname.includes('home')} 
        onBack={()=>navigate(-1)}
      >
        {title}
      </NavBar>
      <Outlet/>
    </Suspense>
  )
}
