import React, { Suspense, useState } from 'react'
import { NavBar,Skeleton,Form,Button,Input, Radio, Space,Picker, Toast ,Popup, List} from 'antd-mobile'
import { loans_month } from '../../utils/options'
import { equalityCorpus, equalityInterset, installmentRate } from '../../utils/util'
import './index.scss'
export default function Calculator() {
  const [form] = Form.useForm()
  const [type,setType] = useState(1)
  const [loanData,setLoanData] = useState({})
  const [visible,setVisible] = useState(false)
  const onFinish = (values) => {
    
    const { money,nper,type:rate_type,rate,payment } = values
			if(!/^[1-9]\d*$/.test(money)){
        return Toast.show('金额只能填整数')
			}
			const regex = /^(?:[0-9]\d{0,2}(?:\.\d{1,4})?|0(?:\.\d{1,4})?)$/;
			if(!regex.test(rate)){
        return Toast.show('利率填写错误')
			}
			if(rate_type === 1){
        const data = payment === 1 ? equalityInterset(money*10000,nper,rate) : equalityCorpus(money*10000,nper,rate)
        setLoanData(data)
        setVisible(true)
			}else{ 
        const data =  installmentRate(money*10000,nper,rate)
        setLoanData(data)
        setVisible(true)
			}
  }
  return (
    <Suspense fallback={
        <>
          <Skeleton.Title animated />
          <Skeleton.Paragraph lineCount={5} animated />
        </>
      }
    >
      <NavBar className='crm-header' backArrow={null}>贷款计算器</NavBar>
      <Form
        form={form}
        layout='horizontal'
        onFinish={onFinish}
        footer={
          <Button block type='submit' color='primary' size='large'  >
            查看计算结果
          </Button>
        }
      >
        <Form.Item 
          name='money' 
          label='贷款金额'
          extra='万' 
          rules={[{required:true,message:'金额必填'}]} 
        >
          <Input type="number" autoComplete='off' placeholder='请输入'/>
        </Form.Item>
        <Form.Item
          name='nper'
          label='申请期数'
          rules={[{required:true,message:'期数必选'}]}
        >
          <Picker
            columns={[loans_month]}
            onConfirm={val => form.setFieldValue('nper',val)}
          >
            {(items,{open})=>{
              return (
                <Space onClick={open} align='center'>
                  {
                    items.every(item => item === null) ? <span style={{color:'#CCCCCC'}}>请选择</span> : items.map(item => item.label)
                  }  
                </Space>
              )
            }}
          </Picker>
        </Form.Item>
        <Form.Item 
          name="type" 
          label='利率类型'
          rules={[{required:true,message:'利率类型必选'}]}
        >
          <Radio.Group onChange={val => setType(val)}>
            <Radio value={1}>年化利率</Radio>
            <Radio value={2}>分期利率</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item 
          name='rate' 
          label={type === 1 ? '年化利率' : '分期利率'} 
          extra='%'
          rules={[{required:true,message:'利率必填'}]}
        >
          <Input type="number" autoComplete='off' placeholder='请输入'/>
        </Form.Item>
        {
          type === 1 ? (
            <Form.Item 
              name="payment" 
              label='还款方式'
              rules={[{required:true,message:'还款方式必选'}]}
            >
              <Radio.Group>
                <Radio value={1}>等额本息</Radio>
                <Radio value={2}>等额本金</Radio>
              </Radio.Group>
            </Form.Item>
          ) : null
        }
      </Form>

      <Popup 
        visible={visible}
        onMaskClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        style={{zIndex:9999999}}
        bodyStyle={{ 
          height: '80vh',
          overflowY:'scroll',
          padding:'15px 0',
        }}
      >
        <NavBar backArrow={false}>
          <div>
            <div>还款明细</div>
            <div className='my-nav-bar-subtitle'>以下结果仅供参考,实际月供以银行计算为准</div>
          </div>
        </NavBar>
        <List>
          <List.Item extra={loanData.loan_amount/10000 + '万'}>贷款金额</List.Item>
          <List.Item extra={`${loanData.nper/12}年 (${loanData.nper}期)`}>贷款年限</List.Item>
          <List.Item extra={loanData.rant+'%'}>贷款利率</List.Item>
          {
            type === 2 ? <List.Item extra={loanData.irr+'%'}>近似折算年化利率(单利)</List.Item> : null
          }
          <List.Item extra={loanData.total_interest}>利息总额</List.Item>
          <List.Item extra={loanData.money_interest}>本息合计</List.Item>
        </List>
        <div className='calculator-list'>
          { 
            loanData && loanData.list && loanData.list.map(item => {
              return (
                <div className='list-item'>
                  <div className='nper'>第{item.key+1}期</div>
                  <div className='data'>
                    <div className='total'>月供:￥{item.month_payment}</div>
                    <div className='money'>
                      <span>本金:{item.month_corpus}</span>
                      <span>利息:{item.month_interest}</span>
                    </div>
                    {
                      type === 1 ? (
                        <div className='residue'>剩余本金:{item.residue_money}</div>
                      ) : null
                    }
                  </div>
                </div> 
              )
            })
          }
        </div>
      </Popup>
    </Suspense>
  )
}
