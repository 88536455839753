import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { NavBar,Skeleton } from 'antd-mobile'

export default function Outside() {
  const [title,setTitle] = useState(null)
  const { pathname } = useLocation()
  useEffect(() => {
      document.title = '福利大放送'
      if(pathname.includes('car')){
        setTitle('预约试驾')
      }else if(pathname.includes('success')){
        setTitle('预约成功')
      }else if(pathname.includes('lotteryLogin')){
        setTitle('福利大放送')
      }else if(pathname.includes('lotteryPrize')){
        setTitle('福利大放送')
      }else if(pathname.includes('lotterySuccess')){
        setTitle('我的奖品')
      }
  },[pathname])
  return (
    <Suspense fallback={
        <>
          <Skeleton.Title animated />
          <Skeleton.Paragraph lineCount={5} animated />
        </>
      }
    >
      <NavBar 
        className='crm-header' 
        backArrow={null}
      >
        {title}
      </NavBar>
      <Outlet/>
    </Suspense>
  )
}